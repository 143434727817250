import styled from 'styled-components';
import { Container } from '../Container';

export const Wrapper = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: row;
  text-align: right;

  @media (max-width: 750px) {
    flex-direction: column;
    text-align: center;

    svg {
      flex-order: 0;
      max-width: 300px;
      width: 100%;
      height: auto;
      padding-bottom: 1rem;
    }
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  padding-left: 2rem;

  @media (max-width: 750px) {
    padding-left: 0;
  }
`;
