import React from 'react';
import { Wrapper, Content, Background, BackgroundWrapper } from './styles';
import SmallBusinessIllustration from 'assets/illustrations/small-business.inline.svg';

export const SmallBusiness: React.FC = () => (
  <Wrapper>
    <SmallBusinessIllustration />
    <Content>
      <h2>We love local businesses.</h2>
      <p>
        We’re proud that our platform provides accessible advertising to small
        businesses. 80% of our advertisers are local organizations and
        businesses.
      </p>
    </Content>
  </Wrapper>
);
