import React from 'react';
import {
  Wrapper,
  Content,
  BackgroundWrapper,
  MarketingTool,
  MarketingToolIcon,
  MarketingToolText,
  MarketingTools,
  BottomBackground,
  TopBackground,
  ContentWrapper,
} from './styles';
import SmsTool from 'assets/images/sms-tool.inline.svg';
import WebBuilder from 'assets/images/web-builder.inline.svg';
import Contour1 from 'assets/background/contour1.inline.svg';
import Contour2 from 'assets/background/contour2.inline.svg';
import { VerticalDivider } from 'components/common';

export const Tools: React.FC = () => (
  <BackgroundWrapper>
    <ContentWrapper>
      <TopBackground>
        <Contour1 />
      </TopBackground>
      <Wrapper>
        <Content>
          <h2>Leverage our marketing tools designed to drive growth.</h2>
          <p>
            We’re dedicated to building features that will help our customers
            succeed.{' '}
          </p>
        </Content>
        <MarketingTools>
          <MarketingTool>
            <MarketingToolIcon>
              <SmsTool />
            </MarketingToolIcon>
            <MarketingToolText>
              <h3>Mass Texting Tool</h3>
              <p>
                Advertising with Paneau gives you access to our free SMS tool
                which you can use to send mass texts to your clients.
              </p>
            </MarketingToolText>
          </MarketingTool>
          <VerticalDivider height="5rem" />
          <MarketingTool>
            <MarketingToolIcon>
              <WebBuilder />
            </MarketingToolIcon>
            <MarketingToolText>
              <h3>Basic Webpage Builder</h3>
              <p>
                Use our simple website builder creates landing pages for deals
                and promotions.
              </p>
            </MarketingToolText>
          </MarketingTool>
        </MarketingTools>
      </Wrapper>
      <BottomBackground>
        <Contour2 />
      </BottomBackground>
    </ContentWrapper>
  </BackgroundWrapper>
);
