import React from 'react';
import Testimonial from './Testimonial';
import Michael from 'assets/illustrations/characters/michael.inline.svg';
import Kara from 'assets/illustrations/characters/kara.inline.svg';
import Jonathan from 'assets/illustrations/characters/jonathan.inline.svg';
import { Wrapper, Container } from './styles';
import { HeaderBackground } from 'components/theme';

export const Testimonials = () => {
  return (
    <Wrapper>
      <HeaderBackground />
      <Container>
        <Testimonial
          face={Michael}
          name="Michael F"
          organization="SPARC"
          quote="Paneau has been a great marketing resource, driving traffic directly to our retail shops."
        />
        <Testimonial
          face={Kara}
          name="Kara R"
          organization="Gold Light Digital Marketing"
          quote="Paneau allows us to quickly and easily update our ads based on our daily events and provides us with valuable insight into how well they’re received. Great team of people with great results! "
        />
        <Testimonial
          face={Jonathan}
          name="Jonathan K"
          organization="MD, MPH"
          quote="Not only do consumers see our ads in rideshare cars, they also take photos of the ad and tag us in their Instagram stories, exponentially increasing our marketing reach."
        />
      </Container>
    </Wrapper>
  );
};
