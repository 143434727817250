import React from 'react';
import CaptiveAudienceIllustration from 'assets/illustrations/captive-audience.inline.svg';
import { Wrapper, Content } from './styles';
import { HeaderBackground } from 'components/theme';

export const CaptiveAudience: React.FC = () => {
  return (
    <Wrapper>
      <Content>
        <h2>Reach captive audiences while they’re on the move.</h2>
        <p>
          Get your brand in front of valuable audiences during the most boring
          part of their day — their commute.
        </p>
      </Content>
      <CaptiveAudienceIllustration />
    </Wrapper>
  );
};
