import React from 'react';
import { Wrapper, Logos, Logo } from './styles';
import Teeth from 'assets/logos/teeth.png';
import WDFM from 'assets/logos/wdfm.png';
import ZeroDown from 'assets/logos/ZeroDown.svg';
import Nurx from 'assets/logos/nurx.svg';
import Grain from 'assets/logos/grain.svg';

export const Advertisers: React.FC = () => {
  return (
    <Wrapper>
      <h2>Select Advertisers</h2>
      <Logos>
        <Logo src={ZeroDown} />
        <Logo src={WDFM} />
        <Logo src={Teeth} />
        <Logo src={Nurx} />
        <Logo src={Grain} />
      </Logos>
    </Wrapper>
  );
};

export default Advertisers;
