import styled from 'styled-components';

import { Container } from '../Container';
import { VerticalDivider } from 'components/common/VerticalDivider';

export const BackgroundWrapper = styled.div`
  width: 100%;
  overflow: visible;
  position: relative;
  padding: 4rem 0 5rem;
`;

export const TopBackground = styled.div`
  z-index: -1;
  position: absolute;
  top: -6rem;
  left: 0;
  height: 6rem;
  width: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const BottomBackground = styled.div`
  z-index: -1;
  position: absolute;
  bottom: -8rem;
  left: 0;
  height: 9rem;
  width: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: #f4f8fb;
  position: relative;
`;

export const Wrapper = styled(Container)`
  text-align: left;
  align-items: center;
  padding: 2rem 0 1rem;
`;

export const Content = styled.div`
  flex-grow: 1;
`;

export const MarketingTools = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;

    ${VerticalDivider} {
      display: none;
    }
  }

  @media (max-width: 585px) {
    flex-direction: column;
  }
`;

export const MarketingTool = styled.div`
  max-width: 425px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  @media (max-width: 845px) {
    flex-direction: column;
  }

  @media (max-width: 700px) {
    flex-direction: row;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const MarketingToolIcon = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  width: 160px;

  svg {
    width: 100%;
  }

  @media (max-width: 845px) {
    width: 100px;
    height: auto;
  }
`;

export const MarketingToolText = styled.div`
  text-align: center;
`;
