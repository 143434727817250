import React, { useContext } from 'react';
import { Container, FadeTransition, Form } from 'components/common';
import {
  IntroWrapper,
  Details,
  SwitchFormTypeLink,
  SignInText,
} from 'components/common/Form/styles';
import { Center } from 'components/common/Form/styles';
import SignUpFormContextProvider, {
  SignUpFormContext,
} from 'components/common/Form/SignUpFormContext';
import { SwitchTransition } from 'react-transition-group';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

export const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/header.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <BackgroundImage
      Tag="div"
      fluid={data.file.childImageSharp.fluid}
      backgroundColor={`#040e18`}
    >
      <IntroWrapper as={Container}>
        <Details>
          <h1>Advertise inside Ubers & Lyfts</h1>
          <h4>
            Instantly launch promotions and ads to captive audiences where &
            when you'd like to reach them.
          </h4>
          <SignUpFormContextProvider>
            <Form product="advertising" />
            <SwitchTypeLink />
          </SignUpFormContextProvider>
          <Center>
            <SignInText>
              Already have an account?{' '}
              <a href={`${process.env.GATSBY_PORTAL_BASE_URL}/login`}>
                Sign In
              </a>
            </SignInText>
          </Center>
        </Details>
      </IntroWrapper>
    </BackgroundImage>
  );
};

const SwitchTypeLink = () => {
  const { showDemoForm, showSignUpForm, activeForm } = useContext(
    SignUpFormContext
  );
  return (
    <Center>
      <SwitchTransition>
        <FadeTransition key={activeForm}>
          {activeForm === 'demosuccess' ? (
            <div />
          ) : (
            <SwitchFormTypeLink
              role="button"
              onClick={() =>
                activeForm === 'demo' ? showSignUpForm() : showDemoForm()
              }
            >
              {activeForm === 'demo' ? 'or sign up' : 'or request a demo'}
            </SwitchFormTypeLink>
          )}
        </FadeTransition>
      </SwitchTransition>
    </Center>
  );
};
