import React from 'react';
import { Container } from 'components/landing';
import {
  Map as StyledMap,
  Content,
  Description,
  StyledBackgroundImage,
} from './styles';
import { useStaticQuery, graphql } from 'gatsby';

export const Map: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/LandingPageMap.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container>
      <StyledBackgroundImage
        Tag="div"
        fluid={data.file.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <StyledMap>
          <Content>
            <h2>Target specific locations and times to show your ads.</h2>
            <Description>
              You decide when and where to run ads. Target specific times, such
              as commuter hours and weekends, and special events like holidays
              and sports games.
            </Description>
          </Content>
        </StyledMap>
      </StyledBackgroundImage>
    </Container>
  );
};
