import React from 'react';
import {
  Wrapper,
  Bio,
  BioTextWrapper,
  Quote,
  StarsWrapper,
  EndQuote,
  Card,
  StarsWrapperProps,
  QuoteWrapper,
} from './styles';
import Star from 'assets/icons/star.inline.svg';

interface TestimonialProps {
  face: any;
  name: string;
  organization: string;
  quote: string;
}

const Testimonial: React.FC<TestimonialProps> = props => {
  const quoteSplit = props.quote.split(' ');
  const len = quoteSplit.length;
  return (
    <Wrapper>
      <Card>
        <Bio>
          <props.face />
          <BioTextWrapper>{`${props.name}, ${props.organization}`}</BioTextWrapper>
        </Bio>
        <QuoteWrapper>
          <Stars screenSize="small" />
          <Quote>
            {quoteSplit.slice(0, len - 1).join(' ')}{' '}
            <EndQuote>{quoteSplit[len - 1]}</EndQuote>
          </Quote>
        </QuoteWrapper>
        <Stars screenSize="large" />
      </Card>
    </Wrapper>
  );
};

const Stars: React.FC<StarsWrapperProps> = props => (
  <StarsWrapper {...props}>
    <Star />
    <Star />
    <Star />
    <Star />
    <Star />
  </StarsWrapper>
);

export default Testimonial;
