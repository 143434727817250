import styled from 'styled-components';
import { Container } from '../Container';
import { VerticalDivider as CommonVerticalDivider } from 'components/common/VerticalDivider';

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  text-align: right;
  align-items: center;

  @media (max-width: 750px) {
    flex-direction: column;
    text-align: center;

    svg {
      flex-order: 0;
      max-width: 200px;
      height: auto;
      padding-bottom: 1rem;
    }
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  padding-left: 2rem;

  @media (max-width: 750px) {
    padding-left: 0;
  }
`;

export const Metrics = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const MetricWrapper = styled.div`
  max-width: 13rem;
  text-align: center;
  position: relative;

  :after {
    right: 0;
    height: 50%;
    width: 2px;
    background-color: #000;
  }

  & + & {
    padding-left: 1rem;
  }

  @media (max-width: 600px) {
    max-width: unset;
    text-align: left;

    &:not(:first-child) {
      padding-left: 0;
      padding-top: 1rem;
    }
  }
`;

export const MetricTitle = styled.span`
  font-weight: 700;
`;

export const VerticalDivider = styled(CommonVerticalDivider)`
  @media (max-width: 750px) {
    margin: 0 0.5rem;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
