import styled from 'styled-components';
import { Container as CommonContainer } from 'components/common';

export const Container = styled(CommonContainer)`
  padding: 4rem 2rem;

  @media (max-width: 1100px) {
    padding: 2.5rem 2rem;
  }

  @media (max-width: 450px) {
    padding: 2.5rem 0.5rem;
  }
`;
