import styled from 'styled-components';
import { Container } from '../Container';

export const Wrapper = styled(Container)`
  text-align: left;
  display: flex;
  align-items: center;

  @media (max-width: 685px) {
    flex-direction: column;

    svg {
      max-width: 300px;
    }
  }
`;

export const Content = styled.div`
  padding: 1rem;
  flex-grow: 1;

  p {
    margin-bottom: 0;
  }
`;
