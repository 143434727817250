import React from 'react';
import { Header } from 'components/theme';
import { Layout, SEO, NotificationBanner } from 'components/common';
import {
  Intro,
  Map,
  Testimonials,
  Analytics,
  CaptiveAudience,
  Tools,
  SmallBusiness,
  Advertisers,
} from 'components/landing';

export default () => (
  <Layout>
    <SEO />
    <NotificationBanner />
    <Header product="advertising" />
    <Intro />
    <Testimonials />
    <CaptiveAudience />
    <Map />
    <Analytics />
    <Tools />
    <SmallBusiness />
    <Advertisers />
  </Layout>
);
