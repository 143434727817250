import styled from 'styled-components';
import { Container as CommonContainer } from '../Container';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const Container = styled(CommonContainer)`
  flex-direction: row;
  justify-content: space-between;
  display: flex;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;
