import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const Map = styled.div`
  border-radius: 40px;
  height: 500px;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: 1100px) {
    height: 400px;
  }

  @media (max-width: 450px) {
    padding: 2rem 0.5rem;
    height: 300px;
  }
`;

export const Content = styled.div`
  padding: 3rem 2rem;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;

  h2,
  p {
    z-index: 1;
  }

  p {
    max-width: 50%;
  }

  @media (max-width: 450px) {
    p {
      max-width: 75%;
    }
  }
`;

export const Description = styled.p`
  @media (max-width: 450px) {
    display: none;
  }
`;

export const StyledBackgroundImage = styled(BackgroundImage)`
  background-position: bottom right;
  background-size: cover;
  overflow: hidden;
  border-radius: 40px;
`;
