import styled from 'styled-components';
import beginquote from 'assets/icons/beginquote.svg';
import endquote from 'assets/icons/endquote.svg';

export const Card = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 1px 1px 25px 0 rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  width: 100%;

  @media (max-width: 1100px) {
    width: 100%;
    flex-direction: row;
    max-width: 700px;
    margin: 0 auto;
    align-items: center;
  }

  @media (max-width: 535px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 33%;

  & + & {
    padding-left: 1rem;
  }

  @media (max-width: 1100px) {
    width: 100%;
    padding: 0;

    & + & {
      padding-left: 0;
      padding-top: 1rem;
    }
  }
`;

export const Bio = styled.div`
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;

  svg {
    height: 6rem;
    width: 6rem;
    flex-shrink: 0;
  }

  @media (max-width: 1100px) {
    flex-direction: column;

    svg {
      width: 4rem;
      height: 4rem;
    }
  }

  @media (max-width: 535px) {
    flex-direction: row;
  }
`;

export const BioTextWrapper = styled.div`
  flex-grow: 1;
  font-weight: 700;
  padding-left: 1rem;

  @media (max-width: 1100px) {
    text-align: center;
    padding: 1rem 0;
    width: 140px;
  }
`;

export const QuoteWrapper = styled.div`
  padding: 0.5rem 1rem;

  @media (max-width: 1100px) {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Quote = styled.span`
  position: relative;
  padding: 0.5rem 1rem;

  :before {
    position: absolute;
    content: '';
    width: 18px;
    height: 18px;
    top: 10px;
    left: -0.35rem;

    background-image: url(${beginquote});
  }

  @media (max-width: 1100px) {
    padding-left: 1rem;
  }
`;

export const EndQuote = styled.span`
  position: relative;

  :after {
    position: absolute;
    content: '';
    width: 18px;
    height: 18px;
    bottom: 0;
    right: -1.35rem;

    background-image: url(${endquote});
  }
`;

export interface StarsWrapperProps {
  screenSize: 'small' | 'large';
}

export const StarsWrapper = styled.div<StarsWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 12rem;
  margin: 0 auto;
  text-align: center;

  svg {
    height: 1.5rem;
    width: 2rem;
  }

  @media (max-width: 1100px) {
    display: ${props => (props.screenSize === 'large' ? 'none' : 'block')};

    svg {
      height: 1.5rem;
    }
  }

  @media (min-width: 1101px) {
    display: ${props => (props.screenSize === 'large' ? 'block' : 'none')};
  }

  @media (max-width: 535px) {
    display: ${props => (props.screenSize === 'large' ? 'block' : 'none')};
  }
`;
