import React from 'react';
import {
  Wrapper,
  MetricWrapper,
  Metrics,
  Content,
  MetricTitle,
  VerticalDivider,
} from './styles';
import AnaylticsImage from 'assets/images/analytics.inline.svg';

export const Analytics: React.FC = () => (
  <Wrapper>
    <AnaylticsImage />
    <Content>
      <h2>View analytics on your ads.</h2>
      <p>View live metrics to see how your ads are performing.</p>
      <Metrics>
        <Metric
          title="Impressions"
          description="A timeline of impressions as your ads are shown across the city."
        />
        <VerticalDivider height="5rem" />
        <Metric
          title="Interactions"
          description="How many passengers are interacting with your ads."
        />
        <VerticalDivider height="5rem" />
        <Metric
          title="Reroutes"
          description="If applicable, the number of passengers that have rerouted to your business."
        />
      </Metrics>
    </Content>
  </Wrapper>
);

interface MetricProps {
  title: string;
  description: string;
}

const Metric: React.FC<MetricProps> = props => (
  <MetricWrapper>
    <MetricTitle>{props.title}</MetricTitle>
    <div>{props.description}</div>
  </MetricWrapper>
);
